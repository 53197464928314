<template>
  <masked-text-input
    :mask="{ regex: `([\\d]+[dhm]\\s?)+` }"
    placeholder="ie. 1h or 2h 30m or 2d"
    left-icon="stopwatch"
    :value="value"
    @input="emit"
    v-bind="{ classes, label, required, error, description, hint }">
    <slot />
  </masked-text-input>
</template>
<script>

export default {

  props: {
    value: String,
    description: String,
    hint: String,
    error: [Object,Array],
    label: {
        type: Boolean,
        default: true
    },
    required: Boolean,
    classes: [Object,String,Number,Array]
  },

  methods: {
    emit(value) {
        this.$emit('input', value)
    },
  },
  
}
</script>